import {
  Badge,
  Button,
  Form,
  FormFields,
  Input,
  Layout,
  Link,
  Modal,
  Select,
  Space,
  Table,
  TableProps,
  Title,
  Toggle,
  Text,
} from '@myneva/ui'
import '@myneva/ui/src/styles/baseline.css'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { countries, languages, statuses } from './data'
import { sorter } from '@myneva/ui/build/components/Table/Table.utils'
import { useAuth } from 'react-oidc-context'
import { Product, SurveyInfo } from './lib/types'

const isExpired = (row: SurveyInfo) => dayjs(row.validTo).isBefore(dayjs(), 'day')
const isScheduled = (row: SurveyInfo) => dayjs(row.validFrom).isAfter(dayjs(), 'day')
const isActive = (row: SurveyInfo) => !(isExpired(row) || isScheduled(row))

export const KPISurveyAdminPage = () => {
  const auth = useAuth()
  const [idToken, setIdToken] = useState('')
  const [data, setData] = useState<SurveyInfo[]>([])
  const [productsFetched, setProductsFetched] = useState<Product[]>([])
  const [rowToDelete, setRowToDelete] = useState<string | undefined>(undefined)

  const [rowOnFormModal, setRowOnFormModal] = useState<string | undefined>(undefined)
  const [showFormModal, setShowFormModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [showFilters, setShowFilters] = useState<boolean>(true)
  const [filters, setFilters] = useState<{ language?: string; products: string[]; statuses: string[] }>({ products: [], statuses })

  const [searchKey, setSearchKey] = useState<string | undefined>(undefined)

  const [formInstance] = Form.useForm()

  //Fetch surveys when page starts
  useEffect(() => {
    fetchToken().catch((error) => console.error('Error fetching token:', error))
    fetchProducts().catch((error) => console.error('Error fetching products:', error))
    fetchSurveys().catch((error) => console.error('Error fetching surveys:', error))
    // eslint-disable-next-line
  }, [idToken])

  useEffect(() => {
    if (rowOnFormModal) {
      const rowData = data.find((row) => row.surveyId === rowOnFormModal)
      formInstance.setFieldsValue({
        ...rowData,
        validFrom: dayjs(rowData?.validFrom),
        validTo: dayjs(rowData?.validTo),
        products: rowData?.products.map((product) => product.name),
      })
    }
    // eslint-disable-next-line
  }, [rowOnFormModal])

  const columns: TableProps<SurveyInfo>['columns'] = [
    {
      title: 'URL',
      dataIndex: 'url',
      render: (url: string) => (
        <Link size={'sm'} href={url}>
          {`Link`}
        </Link>
      ),
      width: 90,
    },
    {
      title: 'Summary',
      width: '20%',
      sorter: sorter({ fieldName: 'subject' }),
      render: (row) => {
        return (
          <Space vertical size={1}>
            <Text weight={'medium'} size={'sm'}>
              {row.subject}
            </Text>
            <Text size={'sm'}>{row.description}</Text>
          </Space>
        )
      },
    },
    {
      title: 'Status',
      width: 120,
      render: (row) => {
        if (isExpired(row)) {
          return <Badge disabled label={'Expired'} />
        } else if (isScheduled(row)) {
          return <Badge color={'warning'} label={'Scheduled'} />
        } else {
          return <Badge color={'success'} label={'Active'} />
        }
      },
    },
    {
      title: 'Valid period',
      dataIndex: 'validFrom',
      render: (_, { validFrom, validTo }) => `${dayjs(validFrom).format('DD/MM/YYYY')} - ${dayjs(validTo).format('DD/MM/YYYY')}`,
      sorter: 'date',
      defaultSortOrder: 'descend',
      width: 200,
    },
    {
      title: 'Language',
      dataIndex: 'language',
      width: 120,
      render: (row) => {
        return languages.find((language) => language.identifier === row)!.value
      },
    },
    {
      title: 'Countries & Products',
      render: (_, { surveyId, countries, products }) => (
        <Space vertical>
          <Space size={1} style={{ flexWrap: 'wrap' }} alignItems={'center'}>
            <Text size={'sm'} weight={'medium'}>{`Countries: `}</Text>
            {countries.map((country, index) => (
              <Badge key={`${surveyId}-${index}-product`} size={'lg'} label={country} />
            ))}
          </Space>
          <Space size={1} style={{ flexWrap: 'wrap' }} alignItems={'center'}>
            <Text size={'sm'} weight={'medium'}>{`Products: `}</Text>
            {products.map((product, index) => (
              <Badge key={`${surveyId}-${index}-country`} size={'lg'} color={'brandPrimary'} label={product.product_name} />
            ))}
          </Space>
        </Space>
      ),
    },
  ]

  const openDeleteModal = (rowKey: string) => setRowToDelete(rowKey)
  const closeDeleteModal = () => setRowToDelete(undefined)

  const openEditModal = (rowKey?: string) => {
    formInstance.resetFields()
    setRowOnFormModal(rowKey)
    setShowFormModal(true)
  }
  const closeEditModal = () => {
    setRowOnFormModal(undefined)
    setShowFormModal(false)
  }

  const startLoading = () => setLoading(true)
  const stopLoading = () => setLoading(false)

  const fetchToken = async () => {
    setIdToken(auth.user!.id_token!)
  }

  const getHostname = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
      return {
        dataHostname: 'https://data-staging.myneva.cloud/system/products',
        surveyHostname: 'https://staging.survey.services.permalink.myneva.cloud/api/internal/surveys',
      }
    } else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      return {
        dataHostname: 'https://data.myneva.cloud/system/products',
        surveyHostname: 'https://production.survey.services.permalink.myneva.cloud/api/internal/surveys',
      }
    }
    return {
      dataHostname: 'https://data-development.myneva.cloud/system/products',
      surveyHostname: 'https://development.survey.services.permalink.myneva.cloud/api/internal/surveys',
    }
  }

  const fetchProducts = async () => {
    const response = await fetch(getHostname().dataHostname, {
      headers: {
        Authorization: `Bearer ${auth.user!.id_token}`,
      },
    })
    const responseJson = (await response.json()) as any
    setProductsFetched(
      responseJson.sort((a: { product_name: string }, b: { product_name: any }) => a.product_name.localeCompare(b.product_name)),
    )
  }

  const fetchSurveys = async () => {
    const response = await fetch(getHostname().surveyHostname, {
      headers: {
        Authorization: `Bearer ${auth.user!.id_token}`,
      },
    })
    if (!response.ok) {
      throw new Error('Failed to fetch surveys')
    }
    const responseJson = await response.json()
    setData(responseJson)
  }

  const deleteSurvey = async (rowKey: string) => {
    const response = await fetch(getHostname().surveyHostname + '/' + rowKey, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
    if (!response.ok) {
      throw new Error('Failed to delete survey')
    }
  }
  const addNewSurvey = async (survey: SurveyInfo) => {
    const response = await fetch(getHostname().surveyHostname, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify(survey),
    })
    if (!response.ok) {
      throw new Error('Failed to fetch surveys')
    } else {
      return response.json()
    }
  }

  const deleteRow = (rowKey: string) => {
    startLoading()
    deleteSurvey(rowKey).catch((error) => console.error('Error deleting survey:', error))
    setTimeout(() => {
      setData((oldData) => {
        return oldData.filter((row) => row.surveyId !== rowKey)
      })
      stopLoading()
      closeDeleteModal()
    }, 1000)
  }

  const saveRow = (
    rowToSave: Omit<SurveyInfo, 'validFrom' | 'validTo' | 'products'> & { validFrom: Dayjs; validTo: Dayjs; products: string[] },
  ) => {
    const rowToSaveWithDate: SurveyInfo = {
      ...rowToSave,
      validFrom: new Date(rowToSave.validFrom.format('YYYY-MM-DD')),
      validTo: new Date(rowToSave.validTo.format('YYYY-MM-DD')),
      products: rowToSave.products.map((productName) => {
        return productsFetched.find((product) => product.name === productName)!
      }),
    }
    startLoading()
    setTimeout(() => {
      const existingRow = data.findIndex((row) => row.surveyId === rowToSave.surveyId)
      if (existingRow < 0) {
        addNewSurvey(rowToSaveWithDate)
          .then((result) => {
            setData(data.slice().concat({ ...rowToSaveWithDate, surveyId: result!.replace(/"/g, '') }))
          })
          .catch((error) => console.error('Error saving survey', error))
          .finally(() => {
            formInstance.resetFields()
            stopLoading()
            closeEditModal()
          })
      } else {
        addNewSurvey(rowToSaveWithDate).catch((error) => console.error('Error editing survey', error))
        data[existingRow] = rowToSaveWithDate
      }
      formInstance.resetFields()
      stopLoading()
      closeEditModal()
    }, 1000)
  }

  return (
    <>
      <Layout>
        <Layout.Content>
          <Space vertical size={4} alignItems={'stretch'}>
            <Space justifyContent={'space-between'} alignItems={'center'}>
              <Title level={'h6'}>KPI Surveys</Title>
              <Button type={'secondary'} destructive label={'Sign out'} onClick={() => void auth.signoutSilent()} />
            </Space>
            <Space justifyContent={'space-between'} alignItems={'center'}>
              <Space size={4}>
                <Button type={'primary'} label={'Add new survey'} onClick={() => openEditModal()} />
                <Input value={searchKey} onChange={(value) => setSearchKey(value)} placeholder={'Search by subject'} />
              </Space>
              <Toggle primaryText={'Show filters'} value={showFilters} onChange={setShowFilters} />
            </Space>
            {showFilters && (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 24 }}>
                <Select
                  value={filters.language}
                  options={languages.map((language) => ({ label: language.value, value: language.identifier }))}
                  onChange={(language) => setFilters({ ...filters, language: language !== undefined ? `${language}` : undefined })}
                  mode={'single'}
                  placeholder={'Filter by language'}
                />
                <Select
                  value={filters.products}
                  onChange={(products) => setFilters({ ...filters, products: products?.map((product) => `${product}`) || [] })}
                  options={productsFetched.map((product) => ({ label: product.product_name, value: product.name }))}
                  mode={'multiple'}
                  placeholder={'Filter by product'}
                />
                <Select
                  value={filters.statuses}
                  onChange={(statuses) => setFilters({ ...filters, statuses: statuses?.map((status) => `${status}`) || [] })}
                  options={statuses.map((status) => ({ label: status, value: status }))}
                  mode={'multiple'}
                  placeholder={'Filter by status'}
                />
              </div>
            )}
            <Table
              data={data.filter((row) => {
                /** Note: For all filter fields, empty fields == no filter applied! */
                if (searchKey && !row.subject.toLowerCase().includes(searchKey.toLowerCase())) {
                  return false
                }

                const { language, products, statuses } = filters

                // Match against language filter
                if (language && row.language !== language) {
                  return false
                }

                //Match against product filter
                if (products.length && products.some((product) => !row.products.map((p) => p.name).includes(product))) {
                  return false
                }

                // Match against status filter
                if (statuses.length) {
                  if (
                    (isActive(row) && !statuses.includes('Active')) ||
                    (isScheduled(row) && !statuses.includes('Scheduled')) ||
                    (isExpired(row) && !statuses.includes('Expired'))
                  ) {
                    return false
                  }
                }

                return true
              })}
              rowKey={'surveyId'}
              defaultSortColumn={'validFrom'}
              columns={columns}
              actions={[
                {
                  label: 'Edit',
                  icon: 'edit',
                  onClick: (row) => openEditModal(row.surveyId),
                },
                {
                  label: 'Delete',
                  icon: 'delete',
                  destructive: true,
                  onClick: (row) => openDeleteModal(row.surveyId),
                },
              ]}
            />
          </Space>
        </Layout.Content>
      </Layout>
      <Modal
        icon={{
          name: rowOnFormModal ? 'edit' : 'add',
          type: 'brandPrimary',
        }}
        title={rowOnFormModal ? 'Edit survey' : 'Add a new survey'}
        content={
          <Form
            form={formInstance}
            onFinish={saveRow}
            initialValues={{ validFrom: dayjs() }}
            onValuesChange={(changedValues, allValues) => {
              if ('validFrom' in changedValues) {
                // Reset validTo date if a date later than validTo is selected for validFrom
                changedValues.validFrom.isAfter(allValues.validTo) && formInstance.setFieldValue('validTo', undefined)
              }
            }}
          >
            <Space vertical size={4} alignItems={'stretch'}>
              <FormFields.Input hidden label={'ID'} name={'surveyId'} />
              <FormFields.Input
                label={'URL'}
                name={'link'}
                fieldValidation={[
                  {
                    validator: (value) => {
                      try {
                        const link = new URL(value)
                        return link.protocol.startsWith('http')
                      } catch {
                        return false
                      }
                    },
                    errorMessage: 'Please enter a valid URL',
                  },
                ]}
                required
              />
              <FormFields.Input label={'Subject'} name={'subject'} required />
              <FormFields.TextArea label={'Description'} name={'description'} required />
              <Space size={4}>
                <FormFields.DatePicker label={'Valid from'} name={'validFrom'} required />
                <FormFields.DatePicker
                  label={'Valid to'}
                  name={'validTo'}
                  required
                  fieldValidation={[
                    {
                      validator: (value) => {
                        const validFrom = formInstance.getFieldValue('validFrom')
                        return value.isSame(validFrom, 'day') || value.isAfter(validFrom, 'day')
                      },
                      errorMessage: 'End date must be same or after start date',
                    },
                  ]}
                />
              </Space>
              <Space size={4} inline={false}>
                <FormFields.Select
                  options={languages.map((language) => ({ label: language.value, value: language.identifier }))}
                  label={'Language'}
                  name={'language'}
                  mode={'single'}
                  required
                  style={{ flexGrow: 1 }}
                />
                <FormFields.Select
                  options={countries.map((country) => ({ label: country, value: country }))}
                  label={'Countries'}
                  name={'countries'}
                  mode={'multiple'}
                  required
                  style={{ flexGrow: 1 }}
                />
              </Space>
              <FormFields.Select
                options={productsFetched.map((product) => ({ label: product.product_name, value: product.name }))}
                label={'Products'}
                name={'products'}
                mode={'multiple'}
                required
              />
            </Space>
          </Form>
        }
        isOpen={showFormModal}
        onClose={closeEditModal}
        primaryAction={{
          label: loading ? 'Saving' : 'Save survey',
          icon: 'check',
          onClick: formInstance.submit,
          disabled: loading,
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: closeEditModal,
          disabled: loading,
        }}
      />
      <Modal
        icon={{
          name: 'delete',
          type: 'danger',
        }}
        title={'Delete this survey?'}
        description={'This action is not reversible. Once deleted, the survey will be gone forever.'}
        destructive
        isOpen={!!rowToDelete}
        onClose={closeDeleteModal}
        primaryAction={{
          label: loading ? 'Deleting' : 'Yes, delete',
          icon: 'delete',
          onClick: () => {
            rowToDelete && deleteRow(rowToDelete)
          },
          disabled: loading,
        }}
        secondaryAction={{
          label: 'No',
          onClick: closeDeleteModal,
          disabled: loading,
        }}
      />
    </>
  )
}
