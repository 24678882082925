export const oidcParams = {
  localhost: {
    authority: 'https://idp-development.myneva.cloud/realms/myneva',
    client_id: '7bxm2rtsn83lh9f614ycqwpajk',
    redirect_uri: 'http://localhost:3000',
  },
  development: {
    authority: 'https://idp-development.myneva.cloud/realms/myneva',
    client_id: '7bxm2rtsn83lh9f614ycqwpajk',
    redirect_uri: 'https://ui.development.survey.services.permalink.myneva.cloud/',
  },
  staging: {
    authority: 'https://idp-staging.myneva.cloud/realms/myneva',
    client_id: '7bxm2rtsn83lh9f614ycqwpajk',
    redirect_uri: 'https://ui.staging.survey.services.permalink.myneva.cloud/',
  },
  production: {
    authority: 'https://idp.myneva.cloud/realms/myneva',
    client_id: '7bxm2rtsn83lh9f614ycqwpajk',
    redirect_uri: 'https://ui.production.survey.services.permalink.myneva.cloud/',
  },
}
