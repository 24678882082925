import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import { setCoralogixSession } from '../lib/coralogix/coralogix'

interface ContainerProps {
  children?: React.ReactNode
}

const useToken = () => {
  const auth = useAuth()
  return auth.user!.profile.email!
}

export const Container: React.FunctionComponent<ContainerProps> = ({ children }) => {
  const token = useToken()

  useEffect(() => {
    if (token) {
      setCoralogixSession({
        user_id: token,
        user_name: token,
      })
    }
  }, [token]) // Single effect for both username and session

  return <>{children}</>
}
