export const countries = ['FI', 'DE', 'AT', 'NL', 'LU', 'CH', 'UK', 'BE'].sort()

export const languages = [
  {
    value: 'Dutch',
    identifier: 'nl',
  },
  {
    value: 'English',
    identifier: 'en',
  },
  {
    value: 'German',
    identifier: 'de',
  },
  {
    value: 'Finnish',
    identifier: 'fi',
  },
  {
    value: 'French',
    identifier: 'fr',
  },
  {
    value: 'Swedish',
    identifier: 'sv',
  },
].sort((a, b) => a.value.localeCompare(b.value))

export const statuses = ['Expired', 'Active', 'Scheduled'].sort()
