import { CoralogixRum } from '@coralogix/browser'
import { Alert, AppContainer, Button, Space, Title } from '@myneva/ui'
import { tokens } from '@myneva/ui/build/foundation/tokens/allTokens'
import { jwtDecode } from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { AuthProvider, useAuth } from 'react-oidc-context'
import { BrowserRouter } from 'react-router-dom'
import { Container } from './container/Container'
import { KPISurveyAdminPage } from './KPISurveyAdminPage'
import { KeycloakToken } from './lib/types'
import { oidcParams } from './oidcConfig'

declare global {
  interface Window {
    CoralogixRum: typeof CoralogixRum
  }
}

const container = document.getElementById('root')
const root = createRoot(container!)

if (window.CoralogixRum) {
  window.CoralogixRum.init({
    application: 'aws-myneva-platform',
    environment: process.env.REACT_APP_ENVIRONMENT!,
    public_key: 'cxtp_lM5RrfkUzZZM3ftyrDmqJ5pYUBREDn',
    coralogixDomain: 'EU1',
    version: process.env.REACT_APP_VERSION!,
    instrumentations: {
      xhr: true,
      fetch: true,
      web_vitals: true,
      interactions: true,
      custom: true,
      errors: true,
      long_tasks: true,
      resources: true,
    },
    sessionRecordingConfig: {
      enable: true,
      autoStartSessionRecording: true,
      recordConsoleEvents: true,
      sessionRecordingSampleRate: 100,
    },
    traceParentInHeader: {
      enabled: true,
      options: {
        propagateAwsXrayTraceHeader: true,
      },
    },
  })
}

const App = () => {
  const auth = useAuth()
  const [isAdministrator, setAdministrator] = useState(false)

  useEffect(() => {
    const fetchSession = async () => {
      if (auth?.user) {
        const token = jwtDecode<KeycloakToken>(auth.user.access_token.toString())
        //'7bxm2rtsn83lh9f614ycqwpajk' refers to the survey clientId on keycloak
        const adminRoles = token.resource_access?.['7bxm2rtsn83lh9f614ycqwpajk']?.roles || []
        setAdministrator(adminRoles.includes('Admin'))
      }
    }
    fetchSession()
  }, [auth])

  return auth.isAuthenticated ? (
    isAdministrator ? (
      <Container>
        <KPISurveyAdminPage />
      </Container>
    ) : (
      <Container>
        <Alert showIcon message={"You don't have the necessary permissions to acess this page."} type="danger"></Alert>{' '}
      </Container>
    )
  ) : (
    <BrowserRouter>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: `${tokens['color.surface.secondary']}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Space
          vertical
          size={6}
          style={{
            padding: `${tokens['space.6']} ${tokens['space.8']}`,
            backgroundColor: `${tokens['color.surface.primary']}`,
            boxShadow: `${tokens['shadow.lg']}`,
            borderRadius: 16,
          }}
          alignItems={'center'}
        >
          {/* eslint-disable-next-line */}
          <img src={'https://www.myneva.eu/hubfs/MYNEVA%20THEME%202023/Logotypes/LOGO.svg'} style={{ width: '100%', maxWidth: 160 }} />
          <Space vertical size={4} alignItems={'center'}>
            <Title level={'h4'}>Survey Admin</Title>
            <Button type={'primary'} label={'Log in with company account'} onClick={() => void auth.signinRedirect()} />
          </Space>
        </Space>
      </div>
    </BrowserRouter>
  )
}

const isLocalhost = () => typeof window.location != 'undefined' && window.location.href.indexOf('localhost') > -1
let oidcConfig
if (isLocalhost()) {
  oidcConfig = oidcParams.localhost
} else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  oidcConfig = oidcParams.development
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  oidcConfig = oidcParams.staging
} else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  oidcConfig = oidcParams.production
}

root.render(
  <React.StrictMode>
    <AppContainer>
      <AuthProvider {...oidcConfig}>
        <App />
      </AuthProvider>
    </AppContainer>
  </React.StrictMode>,
)
