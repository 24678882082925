interface useCoralogixProps {
  user_id: string
  user_name: string
}

export function setCoralogixSession(props: useCoralogixProps) {
  if (!window.CoralogixRum) {
    return
  }

  window.CoralogixRum.setUserContext({
    user_id: props.user_id,
    user_name: props.user_name,

    user_metadata: {
      role: 'admin',
    },
  })

  if (window.location.href.indexOf('localhost')) {
    window.CoralogixRum.setLabels({
      ...window.CoralogixRum.getLabels(),
      localhost: 'true',
    })
  }
}
